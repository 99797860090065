.o-section {
  padding-top: rem($-o-section-padding);
  padding-bottom: rem($-o-section-padding);
  flex-grow: 1;

  @media (min-width: $md) {
    padding-top: rem($-o-section-padding-lg);
    padding-bottom: rem($-o-section-padding-lg);
  }

  &-center {
    @extend .o-section;

    text-align: center;
  }

  &-compact {
    padding-top: rem(32px);
    padding-bottom: rem(32px);

    @media (min-width: $sm) {
      padding-top: rem(40px);
      padding-bottom: rem(40px);
    }
  }

  &--top0 {
    padding-top: 0;
  }

  &--bottom0 {
    padding-bottom: 0;
  }
}
