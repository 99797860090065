.o-grid {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  margin-left: -$grid-gap-single;
  margin-right: -$grid-gap-single;

  &--reverse {
    flex-direction: row-reverse;

    &\@md {
      @media (min-width: $md) {
        flex-direction: row-reverse;
      }
    }
  }

  &--between {
    justify-content: space-between;
  }

  &--center {
    justify-content: center;
  }

  &--align-center {
    align-items: center;
  }

  &--wide {
    margin-left: 0;
    margin-right: 0;
  }

  &--no-gutters {
    margin-left: 0;
    margin-right: 0;

    > .o-grid__col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  // Temporal hasta que lo metamos en los mixin de resoluciones
  &--xs-column {
    @media (max-width: $xs-max) {
      flex-direction: column;

      &-reverse {
        flex-direction: column-reverse;
      }
    }
  }
  &--block\@max-xs {
    @media (max-width: $xs-max) {
      display: block;
    }
  }

  &--small-gutter {
    margin-left: rem(-$grid-gap / 3);
    margin-right: rem(-$grid-gap / 3);

    .o-grid__col {
      padding-left: rem($grid-gap / 3) !important;
      padding-right: rem($grid-gap / 3) !important;
    }
  }

  &__col {
    flex: 0 0 auto;
    max-width: 100%;
    padding-left: rem($grid-gap-single);
    padding-right: rem($grid-gap-single);

    &--fill {
      flex: 1;

      &\@lg {
        @media (min-width: $lg) {
          flex: 1;
        }
      }
    }

    &--right {
      margin-left: auto;
    }
  }
}
