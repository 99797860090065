html {
  // overflow-x: hidden;  // si hace falta quitar esto habrá que revisar cómo lidiar con el scroll hrz causado entre otras cosas por los elementos animados
}

body {
  @include body-text();

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  // overflow-x: hidden;  // si hace falta quitar esto habrá que revisar cómo lidiar con el scroll hrz causado entre otras cosas por los elementos animados
  padding: 0;
  display: flex;
  flex-flow: column;
  background-color: #f1f1f1;
  justify-content: space-between;
}

* {
  -webkit-tap-highlight-color: transparent;
}

canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}
canvas:focus,
canvas:active,
canvas:link {
  outline: none;
  border: 0;
}

div.divider {
  display: block;
  padding-top: rem(30px);
  border-bottom: 1px solid $light;
  margin-bottom: rem(30px);
}
