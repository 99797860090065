.c-alerts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: $calert-margin-bottom;

  .o-container {
    background-color: white;
  }

  .c-alert {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    &__icon {
      width: 30px;
      height: auto;
      margin-right: rem(9px);
    }
  }

}

.alert-success {
  background-color: transparent;
  color: $darker;
}
