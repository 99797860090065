.c-footer {
  // padding-top: rem(20px);
  padding-bottom: rem(20px);
  margin-top: rem(20px);
  background-color: white;

  &__brand {
    margin-top: rem(20px);
    text-align: center;
    &-img {
      width: 150px;
    }
  }

  &--landing {
    margin-top: 0;
  }
}
