.c-login {
  background-color: white;
  padding-bottom: rem(60px);
  padding-top: rem(50px);

  &__title {
    @include title-h1();
    @media (min-width: $md) {
      text-align: center;
    }
  }

  &__subtitle {
    @include title-h5();
    @media (min-width: $md) {
      text-align: center;
    }
    font-weight: 400;
    color: $dark;
  }

  &__brand {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: rem(40px);
    &-img {
      max-width: 300px;
      height: auto;
    }
  }
}
