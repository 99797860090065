@mixin icon-styles() {
  font-family: 'ico-theme' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin reset-list() {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

@mixin noscroll(){
  position:fixed;
  overflow:hidden;
}

.noscroll {
  @media (min-width: $md) {
    @include noscroll();
  }
}

@mixin screen-readers() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@mixin modal-paddings() {
  padding: rem(53px) 0 rem(16px);

  @media (min-width: $md) {
    padding: rem(64px) 0;
  }
}

@mixin hidden() {

  .u-hide {
    display: none !important;
  }

  @each $bp-key, $bp-value in $breakpoints {
    @media (min-width: $bp-value) {

      .u-hide#{$breakpoint-class}#{$bp-key} {
        display: none !important;
      }
    }
  }

  @each $bp-key, $bp-value in $breakpoints-max {
    @media (max-width: $bp-value) {

      .u-hide#{$breakpoint-class}#{$bp-key} {
        display: none !important;
      }
    }
  }
}

@include hidden();
