.c-project {
  background-color: black;

  &__header {
    & > .o-container {
      padding-top: rem(30px);
      padding-bottom: rem(10px);
      background-color: white;
    }
  }

  &__subheader {
    & > .o-container {
      background-color: white;
      padding-top: rem(20px);
      padding-bottom: rem(40px);
    }

    &-items {
      display: flex;
      flex-wrap: wrap;
      @media (min-width: $md) {
        display: flex;
      }
    }

    &-item {
      font-size: rem(16px);
      display: flex;
      align-items: center;
      text-transform: uppercase;
      padding-left: rem(10px);
      padding-right: rem(10px);
      width: 100%;
      justify-content: flex-start;
      margin-bottom: rem(10px);
      @media (min-width: $md) {
        justify-content: space-around;
        width: auto;
        display: flex;
        padding-left: rem(30px);
        padding-right: rem(30px);
        margin-bottom: rem(0px);
      }

      &:first-child {
        @media (min-width: $md) {
          padding-left: 0;
        }
      }

      & + & {
        // content: ' | ';
        // color: $dark;
        @media (min-width: $md) {
          border-left: 1px solid $dark;
        }
      }
    }

    &-icon {
      display: inline-block;
      margin-right: rem(10px);

      &--topic {
        width: 15px;
        height: auto;
      }

      &--users {
        width: 28px;
      }
    }

    &-item {
    }
  }

  &__body {
    & > .o-container {
      padding-bottom: rem(40px);
      overflow: hidden;
    }
  }

  &__actions {
    a {
      width: 100%;
      display: block;
      margin-bottom: rem(10px);
    }
    @media (min-width: $md) {
      text-align: right;
      a {
        display: inline;
      }
    }
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: rem(20px);
  }

  &__title {
    text-transform: uppercase;
    font-weight: 400;
  }

  &__content {
    padding-bottom: rem(50px);
  }

  &__topics {
    &-title {
      @include subtitle($color: $red);
    }
  }

  &__phase {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
  }

  &__generate-results {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
  }

  &__generate-results-filter {
    padding-top: rem(30px);
    padding-bottom: rem(0px);
    table {
      margin-bottom: rem(40px);
    }
  }
}

.js-project-newtopic {
  display: none;
  background-color: black;
}

.js-hideshow-element {
  display: none;
}
