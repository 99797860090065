.c-btn {
  @include button();
  @include button-colors($color: $white, $background: $primary, $border: $primary, $hover-background: #cc0000, $hover-border: #cc0000, $hover-color: $white);

  &+.c-btn {
    margin-left: rem(5px);
  }

  &:hover {
    @include button--outline();
    border-color: $red;
  }
}

.c-btn--outline {
  @include button();
  @include button--outline();
}

.c-btn--big {
  @include button();
  @include button--big();
}

.c-btn--big-outline {
  @include button();
  @include button--big();
  @include button--outline();
}

.c-btn--small {
  @include button();
  @include button--small();
}

.c-btn--small-outline {
  @include button();
  @include button--small();
  @include button--outline();
}

