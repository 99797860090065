// ICONS INFORMA
// @font-face {
//     font-family: 'ico-theme';
//     font-display: swap;
//     src: url('fonts/ico-theme/icomoon.eot?22fpan');
//     src: url('fonts/ico-theme/icomoon.eot?22fpan#iefix') format('embedded-opentype'),
//          url('fonts/ico-theme/icomoon.otf?22fpan') format('truetype'),
//          url('fonts/ico-theme/icomoon.woff?22fpan') format('woff'),
//          url('fonts/ico-theme/icomoon.svg?22fpan#icomoon') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }


@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-ExtraLight.eot");
  src: url("fonts/Acciona-ExtraLight.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-ExtraLight.otf") format("truetype"),
      url("fonts/Acciona-ExtraLight.woff2") format("woff2"),
      url("fonts/Acciona-ExtraLight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-ExtraLightItalic.eot");
  src: url("fonts/Acciona-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-ExtraLightItalic.otf") format("truetype"),
      url("fonts/Acciona-ExtraLightItalic.woff2") format("woff2"),
      url("fonts/Acciona-ExtraLightItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-Light.eot");
  src: url("fonts/Acciona-Light.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-Light.otf") format("truetype"),
      url("fonts/Acciona-Light.woff2") format("woff2"),
      url("fonts/Acciona-Light.woff") format("woff");

  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-Italic.eot");
  src: url("fonts/Acciona-Italic.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-Italic.otf") format("truetype"),
      url("fonts/Acciona-Italic.woff2") format("woff2"),
      url("fonts/Acciona-Italic.woff") format("woff");

  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-Regular.eot");
  src: url("fonts/Acciona-Regular.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-Regular.otf") format("truetype"),
      url("fonts/Acciona-Regular.woff2") format("woff2"),
      url("fonts/Acciona-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-Italic.eot");
  src: url("fonts/Acciona-Italic.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-Italic.otf") format("truetype"),
      url("fonts/Acciona-Italic.woff2") format("woff2"),
      url("fonts/Acciona-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-Medium.eot");
  src: url("fonts/Acciona-Medium.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-Medium.otf") format("truetype"),
      url("fonts/Acciona-Medium.woff2") format("woff2"),
      url("fonts/Acciona-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-MediumItalic.eot");
  src: url("fonts/Acciona-MediumItalic.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-MediumItalic.otf") format("truetype"),
      url("fonts/Acciona-MediumItalic.woff2") format("woff2"),
      url("fonts/Acciona-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-Bold.eot");
  src: url("fonts/Acciona-Bold.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-Bold.otf") format("truetype"),
      url("fonts/Acciona-Bold.woff2") format("woff2"),
      url("fonts/Acciona-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-BoldItalic.eot");
  src: url("fonts/Acciona-BoldItalic.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-BoldItalic.otf") format("truetype"),
      url("fonts/Acciona-BoldItalic.woff2") format("woff2"),
      url("fonts/Acciona-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-Bold.eot");
  src: url("fonts/Acciona-Bold.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-Bold.otf") format("truetype"),
      url("fonts/Acciona-Bold.woff2") format("woff2"),
      url("fonts/Acciona-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Acciona";
  src: url("fonts/Acciona-BoldItalic.eot");
  src: url("fonts/Acciona-BoldItalic.eot?#iefix") format("embedded-opentype"),
      url("fonts/Acciona-BoldItalic.otf") format("truetype"),
      url("fonts/Acciona-BoldItalic.woff2") format("woff2"),
      url("fonts/Acciona-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
