.u-relative {
  position: relative;
}

.u-block {
  display: block;
}

.u-center-block {
  margin-left: auto;
  margin-right: auto;
}

.u-top {
  top: 0 !important;
}

.u-left {
  text-align: left;
}

.u-right {
  text-align: right;
}

.u-center {
  text-align: center;

  &\@sm {
    @media (min-width: $sm) {
      text-align: center;
    }
  }
}

.u-break {
  display: block;
  clear: both;
}

@include spaces();
