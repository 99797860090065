.c-footer-menu {

  &__items {
    @include reset-list();
    display: flex;
    justify-content: center;
  }
  &__item {
    &+.c-footer-menu__item {
      //margin-left: rem(20px);
      &:before {
        content: ' | ';
        margin-left: rem(5px);
        margin-right: rem(5px);
      }
    }

  }
}
