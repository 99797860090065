.c-main-menu {

  &__items {
    @include reset-list();
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    &+.c-main-menu__item:before {
      content: ' | ';
      margin-left: rem(20px);
      margin-right: rem(20px);
      position: relative;
      top: -1px;
    }
  }

  &__link {
    text-transform: uppercase;
    color: $darker;
    font-weight: 500;
    &:visited {
      //color: $primary;
    }
    &.active {
      text-decoration: none;
    }
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}
