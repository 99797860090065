.c-buttons-container {
  margin-top: rem(20px);
  padding-top: rem(30px);
  padding-bottom: rem(20px);
  border-top: 1px solid $light;

  &--no-border {
    border-top: 0;
  }

  &--right {
  }
}
