.c-projects-table {
  a {
    color: $red;
    text-decoration: underline;

    &:visited {
      color: $red;
      text-decoration: underline;
    }
  }
}
