.title-h1 {
  @include title-h1;
}

.title-h2 {
  @include title-h2;
}

.title-h3 {
  @include title-h3;
}

.title-h4 {
  @include title-h4;
}

.title-h5 {
  @include title-h5;
}

.main-title {
  @include title-h1;
  color: $red;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;

  & + .main-subtitle {
    @include title-h5();
    color: $page__title--color;
    font-weight: 400;
    margin-top: rem(10px);
    margin-bottom: 0;
  }
}

.main-section {
  @include title-h4();
  font-weight: 400;
  color: $red;
}

// .subtitle {
//   @include subtitle();

//   &--header {
//     margin-top: 3rem;
//     margin-bottom: 3rem;
//     span {
//       font-size: rem(38px);
//     }
//   }
// }
