.c-lang-selector {
  @include reset-list();

  @media (min-width: $md){
    text-align: right;
  }

  &__item {
    display: inline;
    &:last-child:before {
      content: '/';
    }

    &-link {
      &:visited {
        //color: $primary;
      }
      &.active {
        text-decoration: underline;
      }
    }
  }
}
