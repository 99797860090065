.c-header {
  padding-top: rem(20px);
  padding-bottom: rem(20px);
  background-color: white;
  margin-bottom: rem(20px);

  @media (min-width: $md) {
    border-bottom: 0;
  }

  &--landing {
    margin-bottom: 0;
    &__brand-img {
      max-width: 100%;
      width: 180px;
      height: auto;
      display: block;
    }
  }
}
