label,
legend {
  @include body-text;

  display: block;
  margin-bottom: rem(4px);
}

legend {
  font-weight: bold;
}

[type="text"],
[type="email"],
[type="password"],
[type="search"],
[type="number"],
textarea,
select {
  @include body-text;

  display: block;
  width: 100%;
  border-radius: $base-radius;
  border: solid $input-border-width $input-border-color;
  background-color: $white;
  padding: rem(13px);
  box-shadow: none;
  appearance: none;

  &::placeholder {
    color: $grey;
  }

  &:focus {
    border-color: $input-focus;
    outline: none;
  }

  &.has-error {
    border-color: $error-color;
  }

  &.has-success {
    border-color: $success-color;
  }

  &[readonly] {
    padding: 0;

    &,
    &:focus {
      border-color: transparent;
    }
  }
}

[type="checkbox"],
[type="radio"] {
  & + label {
    min-height: rem(14px);
    display: inline-block;
    padding-left: rem(8px);
    position: relative;
    cursor: pointer;

    // &::before {
    //   cursor: pointer;
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: -1px;
    //   border: solid $input-border-width $grey;
    //   display: inline-block;
    //   width: 20px;
    //   height: 20px;
    // }
  }
}
// [type="checkbox"],
// [type="radio"] {
//   -webkit-appearance: none;
//   position: absolute;
//   z-index: z(under);
//   opacity: 0;

//   & + label {
//     min-height: rem(14px);
//     display: inline-block;
//     padding-left: rem(28px);
//     position: relative;
//     cursor: pointer;

//     &::before {
//       cursor: pointer;
//       content: '';
//       position: absolute;
//       left: 0;
//       top: -1px;
//       border: solid $input-border-width $grey;
//       display: inline-block;
//       width: 20px;
//       height: 20px;
//     }
//   }

//   &:focus {

//     & + label {

//       &::before {
//         border-color: $input-focus;
//       }
//     }
//   }

//   &:checked {

//     & + label {

//       &::before {
//         border-color: $input-focus;
//       }
//     }
//   }

//   &:disabled {

//     & + label {

//       &::before {
//         background: $input-disabled;
//       }
//     }
//   }

//   &.has-error {

//     & + label {

//       &::before {
//         border-color: $error-color;
//       }
//     }
//   }
// }

// [type="checkbox"] {

//   & + label {

//     &::before {
//       @include icon-styles;

//       border-radius: $base-radius;
//       color: $white;
//       font-size: 0;
//       text-align: center;
//       line-height: 18px;
//       transition: all .2s ease-in-out;
//     }
//   }

//   &:checked {

//     & + label {

//       &::before {
//         background: $input-focus;
//         font-size: rem(10px);
//       }
//     }
//   }
// }

// [type="radio"] {

//   & + label {

//     &::before {
//       border-radius: $circle-radius;
//       transition: all 0.2s ease-out;
//       will-change: border-width, border-color;
//     }
//   }

//   &:checked {

//     & + label {

//       &::before {
//         border-color: $input-focus;
//         border-width: 5px;
//         background-color: $white;
//       }
//     }
//   }
// }

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

[type="reset"],
[type="submit"] {
  @include button;
  @include button-colors();
}

select {
  margin: 0;
  box-sizing: border-box;
  outline: none;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

textarea {
  height: rem(120px);
  resize: vertical;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: rem(20px);
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

// iOS Preventing zoom on form elements
@media screen and (device-aspect-ratio: 2/3),
  /* iPhone < 5: */ screen and (device-aspect-ratio: 40/71),
  /* iPhone 5,
  5c,
  5s,
  iPod Touch 5g */ screen and (device-aspect-ratio: 375/667),
  /* iPhone 6,
  iPhone 6s,
  iPhone 7 portrait/landscape */ screen and (device-aspect-ratio: 9/16),
  /* iPhone 6 Plus,
  iPhone 6s Plus,
  iPhone 7 Plus portrait/landscape */ screen and (device-aspect-ratio: 375/812),
  /* iPhone X,
  iPhone XS */ screen and (device-aspect-ratio: 414/896) {
  /* iPhone Xr, iPhone Xs Max */
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  select:focus,
  textarea {
    font-size: 1rem !important;
  }
}

select {
  background: url("/static/images/utils/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  padding-right: 20px;
}
