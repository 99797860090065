.c-page-header {
  &>.o-container {
    padding-top: rem(30px);
    padding-bottom: rem(20px);
    background-color: white;
    border-bottom: 1px solid $grey;
  }

  .main-title {
    margin: 0;
  }
}
