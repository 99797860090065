// Theme
$darker: #4D4F5C;
$dark: #666666;
$grey: #CCC7C7;
$light: #F1F1F1;
$red: #FF0000;

$primary: $red;
$secondary: $grey;

$page__title--color: black;

$text-base__color: $dark;
$anchor__color: $darker;
$anchor__colorhover: $primary;

// neutral
$white: #ffffff !default;
$black: #000000 !default;
$super-light: #f5f5f6 !default;
$light: #dcdfe0 !default;
$grey: #babcbf !default;
$darker: #494e54 !default;

$dark: #333333 !default;

$light-grey: #e6e6e6 !default;
$pearl-grey: #8a898d !default;
$pale-grey: #f0f4f9 !default;

// blue
$faded-blue: #6b92c0 !default;
$french-blue: #416ca2 !default;
$light-blue: #9bc3f3 !default;
$dark-indigo: #07132f !default;
$dark-grey-blue: #384258 !default;

// orange
$pumpkin-orange: #ff7800 !default;
$bright-orange: #f45600 !default;
$sun-yellow: #ffd02b !default;

// red
$carmine: #a60014 !default;
$faded-red: #ca515f !default;

// elements
$anchor__color: $primary !default;

$highlight-color: $primary !default;
$error-color: $primary !default;
$warning-color: $sun-yellow !default;
$success-color: $primary !default;
$alert-color: $pumpkin-orange !default;

$base-color: $darker !default;
$title-color: $darker !default;
$pretitle-color: $base-color !default;
$subtitle-color: $darker !default;

$input-border-color: $grey !default;
$input-focus: $primary !default;
$input-disabled: $light !default;
