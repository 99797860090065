@mixin button-colors(
  $color: $white,
  $background: $primary,
  $border: $primary,
  $hover-background: $white,
  $hover-border: $white,
  $hover-color: $white
) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus {
    background-color: $hover-background;
    border-color: $hover-border;
    color: $hover-color;
    outline: none;
    text-decoration: none;
  }
}

@mixin button() {
  border-radius: $button-radius;
  display: inline-block;
  border: 1px solid $primary;
  text-align: center;
  font-weight: 400;
  padding: rem(10px) rem(16px) rem(10px);
  font-size: rem(14px);
  line-height: rem(14px);
  text-transform: uppercase;
  text-decoration: none;
  font-family: $font--secondary;
  transition: all 0.3s ease-out;
  background-color: $primary;
  will-change: color, border-color, background-color, width;
  color: white;
  cursor: pointer;

  &:visited {
    color: white;
  }

  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

@mixin button--small() {
  font-size: rem(14px);
  line-height: rem(18px);
  padding: rem(6px) rem(16px) rem(4px);
}

@mixin button--big() {
  font-size: rem(16px);
  line-height: rem(18px);
  padding: rem(10px) rem(26px) rem(10px);
}

@mixin button--outline() {
  background-color: white;
  border-color: $grey;
  color: $dark;

  &:visited,
  &:focus {
    background-color: white;
    border-color: $grey;
    color: $dark;
  }

  &:hover {
    color: $red !important;
    background-color: $red important;
    border-color: $red !important;
  }
  // &:focus {
  //   color: white !important;
  // }
}

@mixin reset-button() {
  border: none;
  padding: 0;
  background: none;
}
