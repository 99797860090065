a {
  color: $anchor__color;
  text-decoration: none;
  transition: all 0.2s ease-out;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $anchor__colorhover !important;
  }

  // &:visited {
  //   color: inherit;
  // }

  &.btn:hover {
    text-decoration: none;
  }

  &:visited {
    color: $anchor__color;
  }
}

label {
  & a:visited {
    color: $anchor__color;
  }
}

a.u-link {
  color: $red;
  text-decoration: underline;

  &:visited {
    color: $red;
    text-decoration: underline;
  }
}
