h1 {
  @include title-h1;
}

h2 {
  @include title-h2;
}

h3 {
  @include title-h3;
}

h4 {
  @include title-h4;
}

h5 {
  @include title-h5;
}

.c-subtitle {
  @include subtitle();
}

h1, h2, h3, h4, h5, h6 {

  a {
    color: inherit;
  }
}
