table {
  @include body-text($darker);

  border: 1px solid $grey;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0 0 rem(30px);
  padding: rem(10px);

  @media (min-width: $sm) {
    margin-bottom: rem(40px);
  }
}

thead,
tbody,
tr,
th,
td {
  border: 0;
  padding: 0;
  white-space: normal;
}

th,
td {
  border-bottom: 1px solid $light;
  padding: rem(5px) rem(8px);
  text-align: inherit;

  @media (min-width: $md) {
    padding: rem(10px) rem(24px);
  }
}

thead {
  td,
  th {
    border-bottom-color: $light;
    color: $dark;
  }
}

tbody {
  th {
    font-weight: normal;
    text-align: left;
  }
}

table.table {
  thead {
    th {
      text-transform: uppercase;
      background-color: #F5F6FA;
      color: #A3A6B4;
    }
  }
  a {
    color: $red;
    text-decoration: underline;

    &:visited {
      color: $red;
      text-decoration: underline;
    }
  }
}
