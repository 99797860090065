.o-container {
  margin-left: auto;
  margin-right: auto;
  padding-right: $o-container__gutter-single;
  padding-left: $o-container__gutter-single;
  max-width: $container-max;
  width: 100%;
  background-color: white;

  &--fluid {
    max-width: 100%;
  }

  &--nogutter {
    padding-right: 0;
    padding-left: 0;
  }

  &--max-width-half {
    padding-right: $o-container__gutter-single;
    padding-left: $o-container__gutter-single;

    @media (min-width: $md) {
      max-width: ($container-max / 2);
      width: 100%;
    }

    .o-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include container_sizes(2, 11);

  &--right {
    margin-left: auto;
    margin-right: 0;
  }

  &-modal {
    padding: rem($modal-container__xs-p-Y) rem($modal-container__xs-p-X);

    @media (min-width: $sm) {
      padding: rem($modal-container__p-Y) rem($modal-container__p-X);
    }
  }
}
