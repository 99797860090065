div.dataTables_wrapper {
  margin-top: rem(20px);
  margin-bottom: rem(20px);
  select {
    min-width: 50px;
  }

  div.dataTables_filter {
    label {
      display: block;
      text-align: right;
    }
  }
}

table.datatable {
  th {
    text-transform: uppercase;
    background-color: #F5F6FA;
    color: #A3A6B4;

    &.sorting:hover {
      cursor: pointer;
    }
  }
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  bottom: 9px;
}

// .page-item {
//   &.active {
//     a.page-link {
//       color: white;
//       background-color: $red;
//       border-color: $red;
//     }
//   }
// }

// .page-link {
//   &:focus,
//   &:active,
//   &:visited{
//     box-shadow: none;
//     outline: none;
//     text-decoration: none;
//   }
// }

.pagination {
  display: flex;
  @include list-unstyled();
  @include border-radius();
}

.page-link {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $darker;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    z-index: 2;
    color: white !important;
    text-decoration: none;
    background-color: $red;
    border-color: $red;
  }

  &:focus {
    z-index: 2;
    outline: $red;
    box-shadow: none;
    color: white !important;
    text-decoration: none;
  }

  // Opinionated: add "hand" cursor to non-disabled .page-link elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      @include border-left-radius($border-radius);
    }
  }
  &:last-child {
    .page-link {
      @include border-right-radius($border-radius);
    }
  }

  &.active .page-link {
    z-index: 1;
    color: white;
    background-color: $red;
    border-color: $red;
  }

  &.disabled .page-link {
    color: $grey;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
