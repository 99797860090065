$font--primary: 'Acciona', sans-serif;
$font--secondary: 'Acciona', sans-serif;
$font--title: 'Acciona', sans-serif;

$base-radius: 0px;
$button-radius: 0px;
$circle-radius: 50%;

$title-margin: 20px;
$paragraphs__margin-bottom: 1.5rem !default;
$lists__margin-bottom: 0.75rem;
$base-margin-bottom: 20px;
$calert-margin-bottom: 20px;

$input-border-width: 1px;

// Managing z-index with function: https://gist.github.com/teyepe/ed84919941a27f16a75a521c6f8594f4
$z-index: (
  'under': -5,
  'zero': 0,
  'default': 5,
  'over': 10
);

$z-level: (
  'card': 10,
  'footer': 20,
  'menu': 30,
  'tooltip': 100,
  'dropdown': 200,
  'top': 500
);

$z-combo: map-merge($z-index, $z-level);


$o-container__gutter:               40px !default;
$o-container__gutter-single:        $o-container__gutter/2 !default;
$container-max: 1240px + $o-container__gutter !default;

$grid-gap:                          24px !default;
$grid-gap-single:                   $grid-gap/2 !default;

$spacer-unit: 8px !default;

$grid-fractions: (
  '1': 1/12,
  '2': 2/12,
  '3': 3/12,
  '4': 4/12,
  '5': 5/12,
  '6': 6/12,
  '7': 7/12,
  '8': 8/12,
  '9': 9/12,
  '10': 10/12,
  '11': 11/12,
  '12': 12/12
) !default;

$-o-section-padding: 40px !default;
$-o-section-padding-lg: 60px !default;

$modal-container__xs-p-Y: 52px !default;
$modal-container__xs-p-X: 16px !default;

$modal-container__p-Y: 64px !default;
$modal-container__p-X: 64px !default;

$url-images: 'images/';
