.c-wysing {
  h1 {
    @include title-h1();
    font-weight: 400;
    text-transform: uppercase;
    margin-top: rem(20px);
  }
  h2 {
    @include title-h2();
    font-weight: 400;
    text-transform: uppercase;
  }
  h3 {
    @include title-h3();
    font-weight: 400;
    text-transform: uppercase;
  }
  h4 {
    @include title-h4();
    font-weight: 400;
    text-transform: uppercase;
  }
  h5 {
    @include title-h5();
    font-weight: 400;
    text-transform: uppercase;
  }
}
