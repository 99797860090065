img.alignleft {
  float: left;
  margin: rem(20px);
  margin-left: 0;
}

img.alignright {
  float: right;
  margin: rem(20px);
  margin-right: 0;
}
