.c-topic {
  &__colour {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: rem(10px);
    position: relative;
    top: 4px;
  }
}
