ul, ol {
	margin: 0 0 1.5rem 0rem;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5rem;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5rem 1.5rem;
}

li {
  margin-bottom: rem(5px);
}
