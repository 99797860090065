.c-landing {
  &__lang {
    text-align: right;
  }

  &__header {
    padding-top: rem(20px);
    background-color: white;
    @media (min-width: $lg) {
      padding-top: rem(80px);
      padding-bottom: rem(80px);
    }
  }

  &__title-h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    color: $red;
    text-align: center;
    font-size: rem(45px);
    line-height: rem(45px);
    margin-top: 0;
    text-transform: uppercase;
    margin-bottom: 0;
    @media (min-width: $lg) {
      text-align: left;
      font-size: rem(55px);
      line-height: rem(60px);
    }
  }

  &__title-h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    color: $red;
    text-align: center;
    font-size: rem(35px);
    line-height: rem(35px);
    text-transform: uppercase;
    margin-bottom: rem(5px);
    @media (min-width: $lg) {
      text-align: left;
      font-size: rem(35px);
      line-height: rem(35px);
    }
  }

  &__ilus {
    max-width: 320px;
    margin: 0 auto;
    display: block;
    position: relative;
    bottom: -85px;
    @media (min-width: $lg) {
      bottom: -0;
      max-width: 380px;
      position: absolute;
      top: -100px;
      left: 50px;
    }
    @media (min-width: $xl) {
      max-width: 500px;
      left: 150px;
    }
  }

  .o-grid__col:nth-child(2) {
    @media (min-width: $lg) {
      position: relative;
    }
  }

  &__objetivo {
    background-color: #dadada;
    text-align: center;
    padding-top: 120px;
    padding-bottom: rem(60px);
    @media (min-width: $lg) {
      text-align: left;
      padding-top: rem(60px);
    }
    > .o-container {
      background-color: transparent;
    }

    h3 {
      color: $red;
      font-size: rem(28px);
    }

    p {
      font-size: rem(22px);
      color: #4b4b4b;
      font-weight: 300;
      strong {
        font-weight: 500;
      }
    }
  }

  &__proceso {
    background-color: white;
    text-align: center;
    padding-top: 50px;
    > .o-container {
      background-color: transparent;
    }

    h3 {
      color: $red;
      font-size: rem(28px);
      @media (min-width: $lg) {
        text-align: left;
      }
    }

    p {
      font-size: rem(22px);
      color: #4b4b4b;
      font-weight: 300;
      strong {
        font-weight: 500;
      }
      @media (min-width: $lg) {
        text-align: left;
      }
    }
  }

  &__fase {
    @media (min-width: $sm) {
      display: flex;
      flex-flow: column;
    }
    h4 {
      color: $red;
      margin-bottom: rem(5px);
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    h5 {
      font-weight: 100;
      font-size: rem(20px);
      color: $red;
      letter-spacing: 1px;
      text-transform: uppercase;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    &:first-child {
      .c-landing__fase-img {
        display: block;
        margin: rem(30px) auto;
        width: 122px;
      }
    }
    &:nth-child(2) {
      .c-landing__fase-img {
        display: block;
        margin: rem(30px) auto;
        width: 111px;
      }
    }

    &:nth-child(3) {
      .c-landing__fase-img {
        display: block;
        margin: rem(30px) auto;
        width: 102px;
      }
    }

    ul {
      @include reset-list();
      text-align: left;
      margin-bottom: rem(50px);
      li {
        padding-left: rem(75px);
        margin-bottom: rem(20px);
        font-size: rem(18px);
        position: relative;
        @media (min-width: $sm) {
          padding-left: rem(50px);
        }
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          width: 33px;
          height: 33px;
          background: url("/static/images/landing/bullet.svg");
          background-repeat: no-repeat;
          background-size: 28px;
        }
      }
    }

    &-time {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: rem(60px);
      @media (min-width: $sm) {
        margin-top: auto;
      }

      img {
        margin-right: rem(20px);
        width: 55px;
      }
      span {
        padding-top: 13px;
        padding-bottom: 13px;
        border-top: 1px solid $red;
        border-bottom: 1px solid $red;
        text-align: left;
        width: 230px;
        display: block;
        text-transform: uppercase;
        font-size: rem(16px);
        color: $red;
      }
    }
  }

  &__resultados {
    background-color: #ebeffa;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 280px;
    background-image: url("/static/images/landing/ilus-footer.png");
    background-size: 1200px;
    background-position: -700px 104%;
    background-repeat: no-repeat;
    @media (min-width: $sm) {
      padding-bottom: 350px;
      background-position: -300px 104%;
    }
    @media (min-width: $lg) {
      padding-bottom: 280px;

      text-align: left;
      background-size: 1400px;
      background-position: -150px 104%;
    }
    @media (min-width: $xl) {
      padding-bottom: 350px;
      background-size: 1800px;
      background-position: right 104%;
    }
    > .o-container {
      background-color: transparent;
    }
    h3 {
      color: $red;
      font-size: rem(28px);
    }

    p {
      font-size: rem(22px);
      color: #4b4b4b;
      font-weight: 300;
      strong {
        font-weight: 500;
      }
    }

    &--withoutilus {
      background-image: none;
      padding-bottom: 50px;
    }
  }

  &__video {
    background-color: white;
    padding-top: 60px;
    padding-bottom: rem(60px);
    h3 {
      color: $red;
      font-size: rem(28px);
      text-align: center;
    }

    &-container {
    }

    video {
      width: 100%;
      max-width: 100%;
    }
  }

  &__footer {
    background-color: $red;
    padding-top: rem(16px);
    padding-bottom: rem(16px);
    > .o-container {
      background-color: transparent;
    }
    text-align: center;
    .c-btn.c-btn--outline {
      color: white;
      border-color: white;
      background-color: transparent;
      &:hover,
      &:focus,
      &:visited {
        color: white !important;
        border-color: white !important;
        background-color: transparent !important;
      }
    }
  }
}
