.c-main {
  background-color: #F0F0F7;
  margin-top: rem(10px);
  margin-bottom: rem(10px);
  &>.o-container {
    background-color: white;
  }
  &__header {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    border-bottom: 1px solid $grey;
    margin-bottom: rem(20px);
  }

}
