/* TYPOGRAPHY */
@mixin display($color: $title-color) {
  font-family: $font--secondary;
  font-size: rem(46px);
  font-weight: bold;
  letter-spacing: -0.5px;
  color: $color;

  @media (min-width: $md) {
    font-size: rem(56px);
    letter-spacing: -0.6px;
  }
}

@mixin title-h1($color: $title-color, $margin-bottom: $title-margin) {
  color: $color;
  font-family: $font--secondary;
  font-size: rem(24px);
  font-weight: 700;
  margin: 0 0 rem(16px);
  line-height: 24px;

  // @media (min-width: $md) {
  // }

  // @media (min-width: $lg) {
  //   font-size: rem(32px);
  //   line-height: rem(40px);
  // }
}

@mixin title-h2($color: $title-color, $margin-bottom: $title-margin) {
  color: $color;
  font-family: $font--secondary;
  font-size: rem(20px);
  font-weight: 700;;
  margin: 0 0 rem($margin-bottom);

  @media (min-width: $md) {
    font-size: rem(24px);
  }
}

@mixin title-h3($color: $title-color, $margin-bottom: $title-margin) {
  color: $color;
  font-family: $font--secondary;
  font-size: rem(24px);
  font-weight: 700;
  letter-spacing: -0.3px;
  margin: 0 0 rem($margin-bottom);

  @media (min-width: $md) {
    font-size: rem(26px);
    letter-spacing: -0.3px;
  }
}

@mixin title-h4($color: $title-color, $margin-bottom: $title-margin) {
  color: $color;
  font-family: $font--secondary;
  font-size: rem(22px);
  font-weight: 500;
  letter-spacing: -0.2px;
  margin: 0 0 rem($margin-bottom);

  @media (min-width: $md) {
    font-size: rem(24px);
    letter-spacing: -0.2px;
  }
}

@mixin title-h5($color: $title-color, $margin-bottom: $title-margin) {
  color: $color;
  font-family: $font--secondary;
  font-size: rem(18px);
  font-weight: 500;
  margin: 0 0 rem($margin-bottom);

  @media (min-width: $md) {
    font-size: rem(20px);
  }
}

@mixin title-h6($color: $title-color, $margin-bottom: $title-margin) {
  color: $color;
  font-family: $font--secondary;
  font-size: rem(16px);
  font-weight: 500;
  margin: 0 0 rem($margin-bottom);
  font-weight: 500;

  @media (min-width: $md) {
    font-size: rem(18px);
  }
}

@mixin subtitle($color: $title-color, $margin-bottom: $title-margin) {
  color: $color;
  font-family: $font--secondary;
  font-size: rem(22px);
  font-weight: 500;
  letter-spacing: -0.2px;
  margin: 0 0 rem($margin-bottom);

  @media (min-width: $md) {
    font-size: rem(24px);
    letter-spacing: -0.2px;
  }
}

// @mixin subtitle() {
//   @include title-h2();
//   font-family: $font--title;
//   text-align: center;
//   position: relative;
//   font-weight: normal;

//   span {
//     display: inline-block;
//     padding-left: 5px;
//     padding-right: 5px;
//     background-color: white;
//     position: relative;
//     z-index: 5;
//     letter-spacing: 1px;

//     font-size: rem(18px);

//     @media (min-width: $sm) {
//       letter-spacing: 3px;
//       font-size: 1.8rem;
//       padding-left: 15px;
//       padding-right: 15px;
//     }
//   }

//   &::before {
//     content: '';
//     width: 100%;
//     position: absolute;
//     border-bottom: 1px solid black;
//     top: 50%;
//     left: 0;
//     margin-top: 1px;
//     z-index: 1;

//     @media (min-width: $sm) {
//       border-bottom: 1px solid black;
//     }
//   }
// }

// @mixin af-list() {
//   list-style: none;
//   padding-left: 0;

//   & li::before {
//     content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
//     color: $golden; /* Change the color */
//     font-weight: bold; /* If you want it to be bold */
//     display: inline-block; /* Needed to add space between the bullet and the text */
//     width: 1em; /* Also needed for space (tweak if needed) */
//     margin-left: -1em; /* Also needed for space (tweak if needed) */
//     font-size: 1.4rem;
//     line-height: 1rem;
//   }
// }

@mixin body-text($color: $base-color) {
  color: $color;
  font-family: $font--primary;
  font-size: rem(15px);
  line-height: 1.3;
  font-weight: 400;

  @media (min-width: $md) {
    font-size: rem(16px);
  }
}
